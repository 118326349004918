/*
 * Proprietary and confidential | 2023 | SoundTalks NV
 */

export const googleMapsKey = 'AIzaSyAnWJSarkqgNTx5ocG3PjsFUKrCNCuAnaQ';
export const autoUpdate = true;
export const baiduMap = false;
export const i18nBackendLoadPath = '/public/locales/{{lng}}/{{ns}}.json';
export const baseURL = 'https://backend.uat.soundtalksweb.com';
export const captchaKey = '0x4AAAAAAAJ4bXXC5KepQkYz';
export const reversedFullName = false;
export const apkDownloadUrl = null;
export const isChinaConfig = false;
export const isDemo = false;
export const enableSubscription = false;
